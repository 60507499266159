*
{
  overflow-x: hidden;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}

.logo p {
 color: red;
}

nav .logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

nav .logo h2
{
  color: #fff;
}

.logo span {
  font-size: 1.2rem;
}

nav ul {
  display: flex;
  /* list-style-type: none; */
}

nav ul li {
  margin: 0rem 0.4rem;
  align-items: center;
  padding: 0.5rem 0.5rem;
}

nav ul li a {
  text-decoration: none;
  font-size: 1.1rem;
  letter-spacing: 2.5px;
  color: #fff;
  padding: 0.7rem 1rem;
}

/* section {
  margin: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

h1,
h2
{
  color: #292b2c;
}

/* Add specific styles for different sections */

#home {
  text-align: center;
}

/* #about {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */



#projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

#projects img {
  max-width: 100%;
  border-radius: 8px;
}

#resume {
  max-width: 800px;
  margin: 0 auto;
}

#achievements ul {
  /* list-style: none; */
  padding: 0;
}

