/* Container for the skills display */
.skills-display-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

/* Title for the skills section */
.skills-display-container h2 {
  font-size: 1.875rem; /* 3xl size */
  font-weight: bold;
  text-align: center;
  margin-bottom: 32px;
  color: #1D4ED8; /* Tailwind blue color */
}

/* Card grid layout for skill categories */
.skills-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
}

@media (min-width: 768px) {
  .skills-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .skills-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Style for each skill card */
.skill-card {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.skill-card:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.skill-card-content {
  padding: 24px;
}

/* Category name style */
.skill-category-name {
  font-size: 1.25rem; /* xl size */
  font-weight: 600;
  margin-bottom: 16px;
  color: #3B82F6; /* Tailwind blue color */
}

/* Grid layout for skill items */
.skill-items-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

@media (min-width: 640px) {
  .skill-items-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Style for each skill item */
.skill-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.skill-item:hover {
  background-color: #F3F4F6; /* Light gray background on hover */
}

.skill-logo {
  width: 60px;
  height: 60px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skill-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Style for the skill name */
.skill-name {
  font-size: 0.875rem; /* sm size */
  font-weight: 500;
}
