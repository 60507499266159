#education {
  text-align: justify;
  height: auto; /* Change height to auto for better responsiveness */
  width: 100%;
  background: #B6D0E2;
  padding: 20px; /* Add padding for better spacing on smaller screens */
  /* color: #fff; */
}

#education h2 {
  font-size: 1.8em; /* Decrease font size for better readability on smaller screens */
  /* padding: 10px; Adjust padding for better spacing on smaller screens */
}

#education p {
  line-height: 1.4; /* Adjust line height for better readability on smaller screens */
  max-width: 100%; /* Remove max-width for full-width paragraphs on smaller screens */
  margin: 10px auto; /* Adjust margin for better spacing on smaller screens */
  font-size: 1.2em; /* Decrease font size for better readability on smaller screens */
}

@media (max-width: 768px) {
  #education {
    width: auto;
      height: auto;
  }

  #education h2 {
      font-size: 1.6em;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  #education p {
      line-height: 1.4;
      max-width: 100%; /* Remove max-width for full-width paragraphs on smaller screens */
      margin: 10px auto;
      font-size: 1.1em;
  }
}
