* {
  font-family: sans-serif;
}
:root {
  --mainColor: #b6d0e2;
  /* --mainColorLight: #A7BEAE; */
  --textColor: #000000;
}

header {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  box-shadow: 0 56px 50px 100px rgba(0, 0, 0, 0.1);
}

nav {
  overflow-y: hidden;
  background: var(--mainColor);
}
nav a {
  /* margin: 0 2rem; */
  padding: 10px;
  color: var(--textColor);
  text-decoration: none;
  font-size: 1.2em;
}

nav a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  /* visibility: hidden; */
  display: none;
  opacity: 0;
  font-size: 1.8rem;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    display: flex;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    background-color: var(--mainColor);
    transition: 1s;
    transform: translateY(-100vh);
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
  /* header
  {
    opacity: 0;
  } */
}
